// 首页
//import request from '@/utils/request'

import store from '@/store'

import wsCmd from '@/plugins/wsCmd'
import {toWebsocketData} from '@/utils/websocketutils'



//获取账号列表
export function getAccountList(data) {
    let _data = toWebsocketData(wsCmd.cmd_2,data)
    return store.dispatch('websocket/sendObj',_data)
}

//获取账号好友列表
export function getAccountFriendList(data) {
    console.log('~~~~~~~~~~~~~~~getAccountFriendList',data)
    let _data = toWebsocketData(wsCmd.cmd_3,data)
    return store.dispatch('websocket/sendObj',_data)
}

//获取账号好友聊天记录
export function getFriendChatLog(data) {
    //标记当前请求好友ID

    let _data = toWebsocketData(wsCmd.cmd_4,data)
    return store.dispatch('websocket/sendObj',_data)
}

//通知后台账号消息已读
export function notifyFriendMsgReaded(data) {
    let _data = toWebsocketData(wsCmd.cmd_5,data)
    return store.dispatch('websocket/sendObj',_data)
}



//发送好友消息
export function sendMsg(data) {
    // console.log(data)

    let _data = toWebsocketData(wsCmd.cmd_6,data)
    return store.dispatch('websocket/sendObj',_data)
}

//保存好友线索信息
export function saveFriendClue(data){
    let _data = toWebsocketData(wsCmd.cmd_7,data)
    return store.dispatch('websocket/sendObj',_data)
}

//获取好友线索信息
export function getFriendClue(data){
    let _data = toWebsocketData(wsCmd.cmd_8,data)
    return store.dispatch('websocket/sendObj',_data)
}

//撤回消息
export function revocationMsg(data){
    let _data = toWebsocketData(wsCmd.cmd_9,data)
    return store.dispatch('websocket/sendObj',_data)
}

//通知后台账号消息已读
export function notifyAccountMsgReaded(data){
    let _data = toWebsocketData(wsCmd.cmd_10,data)
    return store.dispatch('websocket/sendObj',_data)
}

//保存好友联系方式
export function saveCustomContact(data){
    let _data = toWebsocketData(wsCmd.cmd_11,data)
    return store.dispatch('websocket/sendObj',_data)
}
//搜索好友聊天记录
export function searchChatlobKeywords(data){
    let _data = toWebsocketData(wsCmd.cmd_12,data)
    return store.dispatch('websocket/sendObj',_data)
}
//搜索好友聊天记录上下文
export function searchChatlobKeywordsList(data){
    let _data = toWebsocketData(wsCmd.cmd_13,data)
    return store.dispatch('websocket/sendObj',_data)
}

//空号检测
export function checkCustomContact(data){
    let _data = toWebsocketData(wsCmd.cmd_14,data)
    return store.dispatch('websocket/sendObj',_data)
}

//空号检测
export function getAccountFriendCount(data){
    let _data = toWebsocketData(wsCmd.cmd_29,data)
    return store.dispatch('websocket/sendObj',_data)
}