import { AES_Encrypt, AES_Decrypt } from '@/utils/aes'
import { PLAT_TYPE_MENU_TOOL } from '@/constants/plattypemenu-const'
const RIGHT_BAR_WIDTH = 'right_bar_width'
const REMEMBER_ME_KEY = 'syb_webchat_me'

const USER_PLAT_MSG_MENU = "user_plat_msg_menu"


export function setRightBarWidth(data) {
  if (data > 0) {
    return localStorage.setItem(RIGHT_BAR_WIDTH, data)
  }

}

export function getRightBarWidth() {
  const rightbarWidth = localStorage.getItem(RIGHT_BAR_WIDTH)
  return rightbarWidth || 0
}


export function getMe() {
  const me = localStorage.getItem(REMEMBER_ME_KEY)
  if (me) {
    let meStr = AES_Decrypt(me)
    return JSON.parse(meStr)
  }
  return {}
}
export function rememberPassword(data) {
  let dataStr = JSON.stringify(data)
  dataStr = AES_Encrypt(dataStr)
  return localStorage.setItem(REMEMBER_ME_KEY, dataStr)
}
export function unrememberPassword() {
  return localStorage.removeItem(REMEMBER_ME_KEY)
}

//用户平台消息菜单布局
export function getUserPlatMagMenu() {
  //默认配置
  let res = []
  //已记住的配置
  let _res = localStorage.getItem(USER_PLAT_MSG_MENU)
  if (_res) {
    _res = JSON.parse(_res)
  }else{
    _res = []
  }
  //获取存在的长度
  let len = _res.length
  //记住顺序
  let sort = 0
  _res.forEach(element => {
    element.sort = sort++
  });


  for (let item in PLAT_TYPE_MENU_TOOL) {
    let _findItem = _res.find(_item=> _item.value === PLAT_TYPE_MENU_TOOL[item].value)
    let baseItem = PLAT_TYPE_MENU_TOOL[item]
    if(_findItem){
      baseItem.checked = _findItem.checked
      baseItem.sort = _findItem.sort
    }else{
      baseItem.checked = true
      baseItem.sort = len++
    }
    res.push(baseItem)
  }

  //排序
  res.sort((a1,a2)=>{
    return a1.sort - a2.sort
  })

  return res
}

//用户平台消息菜单布局
export function setUserPlatMagMenu(data = []) {
  let dataStr = JSON.stringify(data)
  localStorage.setItem(USER_PLAT_MSG_MENU, dataStr)
}


