<!--
  *  对话布局
-->
<template>
    <el-watermark :font="font" style="height: 100%;" :content="content">
        <Splitpanes class="default-theme" style="background: transparent !important;">
            <pane size="20" style="background: transparent !important;">
                <Left />
            </pane>
            <Pane size="20" style="border:2px solid rgba(223, 229, 244, 0.8); border-radius: 10px;">
                <Middle />
            </Pane>
            <pane size="40"
                style="background: transparent !important;border:2px solid rgba(223, 229, 244, 0.8); border-radius: 10px;">
                <!-- 主内容区 -->
                <router-view id="mainBox" style="flex-shrink: 1;"
                    class="nt__mainbox flex2 flexbox flex-col"></router-view>
            </pane>
            <pane size="20" style="background: transparent !important;">
                <SidebarRight id="rightBox"
                    style="box-sizing: border-box; height: 100%; overflow: hidden;flex-grow: 1;flex-shrink: 1;width:100%"
                    class="flex1;" />
            </pane>
        </Splitpanes>
    </el-watermark>
</template>
<script setup>
import { reactive, watch, computed, inject, onMounted, ref, onUnmounted } from 'vue'
import { Splitpanes, Pane } from 'splitpanes'
import { useStore } from "vuex";
import { time } from 'echarts';
const momentUtil = inject("momentUtil");
const font = reactive({
    color: 'rgba(0, 0, 0, .09)',
})

const dateTime = ref(undefined)
const store = useStore();
const userInfo = computed(() => {
    return store.state.user.user;
});
const timmer = ref(undefined)


const updateDateTime = async () => {
    if (timmer.value) {
        clearTimeout(timmer.value)
    }
    timmer.value = setTimeout(() => {
        dateTime.value = momentUtil.moment().format('YYYY-MM-DD HH:mm:ss');
        updateDateTime()
    }, 60000);

}


const content = computed(() => {
    return `${store.state.user.user?.nickname}-${store.state.user.user?.username}（${dateTime.value}）`
})

onMounted(async () => {
    dateTime.value = momentUtil.moment().format('YYYY-MM-DD HH:mm:ss');
    updateDateTime()
})

onUnmounted(() => {
    if (timmer.value) {
        clearTimeout(timmer.value)
    }
})
</script>