/*
 * 通用常量
 *
 * @Author:    feihu technology-主任：Hihong
 * @Date:      2022-09-06 19:57:29
 * @Wechat:    hihong2024
 * @Email:     hihong@163.com
 * @Copyright  feihu technology （ https://feihu.net ），Since 2016
 */

export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTIONS = ['5', '10', '15', '20', '30', '40', '50', '75', '100', '150', '200', '300', '500'];

//登录页面名字
export const PAGE_PATH_LOGIN = '/login';

//404页面名字
export const PAGE_PATH_404 = '/404';

export const showTableTotal = function (total) {
  return `共${total}条`;
};

export const FLAG_NUMBER_ENUM = {
  TRUE: {
    value: 1,
    desc: '是',
  },
  FALSE: {
    value: 0,
    desc: '否',
  },
};

export const GENDER_ENUM = {
  UNKNOWN: {
    value: 0,
    desc: '未知',
  },
  MAN: {
    value: 1,
    desc: '男',
  },
  WOMAN: {
    value: 2,
    desc: '女',
  },
};

export const USER_TYPE_ENUM = {
  ADMIN_EMPLOYEE: {
    value: 1,
    desc: '员工',
  },

};

export const DATA_TYPE_ENUM = {
  NORMAL: {
    value: 1,
    desc: '普通',
  },
  ENCRYPT: {
    value: 10,
    desc: '加密',
  },

};
export const STATUS_ENUM = {
  VALID: {
    value: 1,
    desc: '有效',
  },
  INVALID: {
    value: 0,
    desc: '无效',
  },
};


//     | 消息类型:1 时间类型，2 通知了类型，3 文本消息 ，4  gif，5 图片，6 视频，7 红包 |
export const MSG_TYPE = {
  TYPE_3: {
    value: 3,
    desc: '文本消息',
  },
  TYPE_5: {
    value: 5,
    desc: '图片',
  },
}

export const DOC_STATUS_1 = {
  STATUS_0:{
    value: 1,
    desc: '有效',
  },
  STATUS_1:{
    value: 0,
    desc: '待初始化',
  },
}

/**
 * 每次检索好友数量
 */
export const SELECT_FRIEND_LIMIT = 100;

export default {
  FLAG_NUMBER_ENUM, GENDER_ENUM, USER_TYPE_ENUM, STATUS_ENUM ,MSG_TYPE
}



