export const PLAT_TYPE_MENU_TOOL = {
  DOUYIN: {
    value: 1,
    desc: "抖音",
    icon: "icon-douyin",
  },
  // KUAISHOU: {
  //   value: 2,
  //   desc: "小红书",
  //   icon: "icon-xiaohongshu",
  // },
  WEIXIN: {
    value: 4,
    desc: "微信",
    icon: "icon-wechat1",
    colorClass: 'm_color_wechat'
  },
  WANGYE: {
    value: 3,
    desc: "网页",
    icon: "icon-zhinengwangyehuise",
    colorClass: 'm_color_webh5'
  },
  KUAISHOU: {
    value: 5,
    desc: "快手",
    icon: "icon-kuaishou11",
    colorClass: 'm_color_kuaishou',
  },

};

export default {
  PLAT_TYPE_MENU_TOOL,
};
