import chatDbCache from '@/idxdb/chat_db_cache'
import { processAccountList, processAccountFriendList } from '@/utils/chat_data_utils'
import { getUserPlatMagMenu } from "@/utils/userSetting";

/**
 * 计算平台未读消息数量
 * @param {*} _state 
 * @param {*} allPlatAccountList 
 * @param {*} platType 
 */
const calNewMsgCount = async (_state, platType, accountList) => {
    let msgCount = 0;
    if (accountList && accountList.length > 0) {
        let arr = accountList.map(item => item.new_msg_count || 0)
        for (let c of arr) {
            msgCount += c
        }
    }

    if (platType == '1') {
        _state.douyinNewMsgCount = msgCount
    } else if (platType == '3') {
        _state.webH5NewMsgCount = msgCount
    } else if (platType == '4') {
        _state.weixinMsgCount = msgCount
    } else if (platType == '5') {
        _state.kuaishouMsgCount = msgCount
    }
}

const state = {
    //当前登录用户ID
    chat_user_id: chatDbCache.getChattingUserId(),
    //当前对话平台ID---不存db--
    chat_plat_id: chatDbCache.getUserChatPlat(),

    //用户系统配置
    user_config: {},
    //用户账号列表
    user_accounts: [],
    //用户当前选中的对话账号--
    user_account_select: {},
    //当前选中账号的好友列表
    user_account_friends: [],
    //当前选中好友，---不存db--
    user_account_select_friend: {},
    //当前选中账号好友数量 ---不存db--
    user_account_friend_count: 0,

    // ------------------------- 以下都不存DB --------------------
    //账号好友数量{}
    accountFriendCount: {},
    //系统配置是否初始化
    sysCfgInfoInited: chatDbCache.getSyCfgInfoInited(),
    //当前正在获取消息的好友ID--------
    fetchingChatLogFriendId: 0,
    // --------------------- 平台未读消息数----------
    //抖音未读消息
    douyinNewMsgCount: 0,
    //webh5 未读消息
    webH5NewMsgCount: 0,
    //快手未读消息数
    kuaishouMsgCount: 0,
    //微信客服未读消息数
    weixinMsgCount: 0,
}

const mutations = {

    /**
  *  设置当前对话用户ID
  * @param {*} state 
  */
    setChatUserId(state, userId) {
        state.chat_user_id = userId
    },
    /**
  *  设置当前对话平台
  * @param {*} state 
  */
    setChatPlatTypeId(state, plattypeId) {
        let platChange = state.chat_plat_id == plattypeId ? false : true
        state.chat_plat_id = plattypeId

        //清空选择账号和好友
        state.user_accounts = []
        state.user_account_select = {}
        state.user_account_friends = []
        state.user_account_select_friend = {}

        //读取缓存中的平台账号信息
        if (platChange) {
            state.user_account_friends = []
            chatDbCache.getUserAccounts(plattypeId).then(res => {
                state.user_accounts = res || []
            })
            //获取选中账号
            chatDbCache.getUserAccountSelect(plattypeId).then(res => {
                state.user_account_select = res || []
            })
        }
    },
    /**
     *  用户系统配置信息
     * @param {*} state 
     */
    setSysCfgInfo(state, data) {
        state.sysCfgInfoInited = true
        state.user_config = data || {}
    },
    /**
     * 设置初始值或者全部覆盖
     * @param {*} state 
     * @param {*} accountList 
     */
    setUserAccountsAll(state, accountList) {
        state.user_accounts = accountList || []
    },
    /**
     * 设置用户对话账号列表信息
     * @param {*} state 
     * @param {*} data 
     */
    setUserAccounts(state, accountListResp) {
        //动作类型 1全部，2新增，3更新，4删除，默认1
        let type = accountListResp.type
        if (!type) {
            return
        }
        //找到平台的聊天账号列表
        let plat_type = accountListResp.data.plat_type
        if (!plat_type) {
            return
        }
        let dataList = accountListResp.data.accountlist
        if (!dataList) {
            dataList = []
        }
        //如果响应数据是当前选中的平台账号数据
        if (plat_type === state.chat_plat_id) {
            let oldData = state.user_accounts
            processAccountList(type, oldData, dataList).then(res => {
                chatDbCache.setUserAccounts(plat_type, res)
                calNewMsgCount(state, plat_type, res)
            })
        } else {
            //获取缓存中的平台账号数据
            chatDbCache.getUserAccounts(plat_type).then(dbAccountList => {
                //处理数据合并，添加等
                return processAccountList(type, dbAccountList, dataList)
            }).then(res => {
                chatDbCache.setUserAccounts(plat_type, res)
                calNewMsgCount(state, plat_type, res)
            })
        }
    },
    /**
     * 设置选中的用户对话账号
     * @param {*} state 
     * @param {*} data 
     */
    setUserAccountSelect(state, data) {
        let accountChange = state.user_account_select?.id == data.id ? false : true
        state.user_account_select = data || {}

        if (accountChange) {
            let accountId = data.id
            chatDbCache.getUserAccountFriends(accountId).then(res => {
                state.user_account_friends = res || []
            })
            //获取平台记住的选中的账号
            chatDbCache.getUserAccountSelectFriend(accountId).then(res => {
                state.user_account_select_friend = res || {}
            })
        }
    },
    /**
     * 设置选中对话账号好友列表
     * @param {*} state 
     * @param {*} data 
     */
    setUserAccountFriendsAll(state, data) {
        state.user_account_friends = data || []
    },

    setUserAccountFriends(state, accountFriendResp) {
        //只更新当前账号的好友账号接管标识（子命令）
        let sub_t = accountFriendResp.sub_t
        if (sub_t === '3_1') {
            let friendlist = accountFriendResp.data.friendlist
            if (!friendlist) {
                friendlist = []
            }
            if (friendlist?.length > 0) {
                let updateFriend = friendlist[0]
                if (updateFriend.cid === state.user_account_select_friend?.cid) {
                    //更新好友的AI接管标识
                    state.user_account_select_friend.is_artificial = updateFriend.is_artificial
                }
            }
        } else {
            let type = accountFriendResp.type
            if (!type) {
                return
            }

            //账号ID
            let accountId = accountFriendResp.data.aid
            if (!accountId) {
                return
            }

            let friendlist = accountFriendResp.data.friendlist
            if (!friendlist) {
                friendlist = []
            }
            //当前对话账号
            let chatAccount = state.user_account_select || {}
            //当前选中的账号是此次返回数据的账号的好友信息
            if (chatAccount.id == accountId) {
                let accountFriends = state.user_account_friends

                //存入DB
                processAccountFriendList(type, accountFriends, friendlist).then(res => {
                    //获取平台记住的选中的账号
                    chatDbCache.getUserAccountSelectFriend(accountId).then(_res => {
                        if (state.user_account_friends?.length > 0) {
                            //未选中，通知选中一个好友id
                            if (!_res?.cid) {
                                //选中第一个
                                state.user_account_select_friend = state.user_account_friends[0]
                                chatDbCache.setUserAccountSelectFriend(accountId, state.user_account_friends[0])
                            }
                        }

                    })
                    chatDbCache.setUserAccountFriends(accountId, res)
                })

            } else {
                //获取缓存中的账号好友数据
                chatDbCache.getUserAccountFriends(accountId).then(dbAccountFriends => {
                    //处理数据合并，添加等
                    return processAccountFriendList(type, dbAccountFriends, friendlist)
                }).then(res => {
                    chatDbCache.setUserAccountFriends(accountId, res)
                })
            }
        }
    },
    //账号选中好友
    userAccountFriendSelect(state, friendItem) {
        if (!friendItem?.cid) {
            return
        }
        state.user_account_select_friend = friendItem || {}
        //获取平台记住的选中的账号
        let accountId = state.user_account_select?.id
        if (!accountId) {
            return
        }
        chatDbCache.setUserAccountSelectFriend(accountId, friendItem)
    },
    //正在加载聊天记录好友ID
    setFetchingChatLogFriendId(state, id) {
        state.fetchingChatLogFriendId = id
    },
    //设置账号好友数量
    setAccountFriendCount(state, data) {
        let { aid, friend_num } = data || {}
        if (aid && friend_num >= 0) {
            state.accountFriendCount[aid] = friend_num
        }
    }

}

const actions = {
    /**
     * 初始化系统数据信息
     * @param {*} _userId 登录用户ID
     * @param {*} selectPlat 选中的平台账号
     */
    doInitSystemData({ commit }, _userId) {
        let userId = _userId
        if (!userId) {
            userId = chatDbCache.getChattingUserId()
        } else {
            chatDbCache.setChattingUserId(userId)
        }
        commit('setChatUserId', userId)



        //查询是否有记住用户上次选中的平台ID
        let plattypeId = chatDbCache.getUserChatPlat()
        if (!plattypeId) {
            //系统默认的平台
            let platTypeMenus = getUserPlatMagMenu()
            plattypeId = platTypeMenus[0].value
        } else {
            chatDbCache.setUserChatPlat(plattypeId)
        }
        commit('setChatPlatTypeId', plattypeId)



        //获取系统配置信息
        chatDbCache.getSyCfgInfo().then(res => {
            commit('setSysCfgInfo', res)
        })
        //用户指定平台的账号列表
        chatDbCache.getUserAccounts(plattypeId).then(res => {
            commit('setUserAccountsAll', res)
        })
        //选中的用户对话账号
        // chatDbCache.getUserAccountSelect(plattypeId).then(res=>{
        //     commit('setUserAccountSelect',res)
        // })
        //设置选中对话账号好友列表
        // chatDbCache.getUserAccountFriends(plattypeId).then(res=>{
        //     commit('setUserAccountFriends',res)
        // })
    },
    doSetChatPlat({ commit }, platId) {
        commit('setChatPlatTypeId', platId)
        chatDbCache.setUserChatPlat(platId)
    },
    doSetUserId({ commit }, userId) {
        commit('setChatUserId', userId)
        chatDbCache.setChattingUserId(userId)
    },
    /**
     * 设置配置信息
     * @param {} param0 
     * @param {*} data 
     */
    doSetSysCfg({ commit }, data) {
        commit('setSysCfgInfo', data)
        chatDbCache.setSyCfgInfo(data)
    },

    /**
     * 设置选中的账号
     */
    doSetUserAccountSelect({ commit, state }, accountInfo) {
        commit('setUserAccountSelect', accountInfo)
        chatDbCache.setUserAccountSelect(state.chat_plat_id, accountInfo)
    },
    /**
     * 设置选中的账号->好友
     */
    doUserAccountFriendSelect({ commit, state }, friendItem) {
        commit('userAccountFriendSelect', friendItem)
    },

}
const getters = {
    getSysConfig(state) {
        return state.user_config || {}
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
