<template>
    <div></div>
</template>

<script setup>
import {
    inject,
    onMounted,
    computed,
    onUnmounted,
    reactive,
    toRefs,
    getCurrentInstance,
    watch,
    ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { getAccountFriendList } from "@/api/chatapi";
import { SELECT_FRIEND_LIMIT } from '@/constants/common-const';
const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const store = useStore();
const bus = inject("$eventBus");
const eventbusCms = inject("eventbusCms");
const wsCmd = inject("wsCmd");
// --------------- 属性 ----------------


// --------------- 方法 -----------------
//系统配置信息初始化
const handleSystemCfgInit = async (respData) => {
    let code = respData.code;
    if (code != 1) {
        return;
    }

    let data = respData.data;
    if (!data) {
        data = {};
    }
    // store.commit("wsData/SET_SYS_CFG_INFO", data);

    // ~~~~~~~~~~~~~~~~~~~ 新缓存 ~~~~~~~~~~~~~~~~~~~~~~~~
    store.dispatch('chat_store/doSetSysCfg', data)
    // ~~~~~~~~~~~~~~~~~~~ end 新缓存 ~~~~~~~~~~~~~~~~~~~~~~~~
    //通知登录成功
    bus.emit(eventbusCms.sysCfgInitSuccess);
}

const handleQiniuTokenResp = async (respData) => {
    let code = respData.code;
    if (code != 1) {
        return;
    }

    let data = respData.data?.qiniu_info;
    if (!data) {
        data = {};
    }
    let token = data.token;
    let domain = data.domain;
    let uploadurl = data.uploadurl;
    let active_time = data.active_time;
    let tokenData = { token, domain, uploadurl, active_time };
    store.commit("qiniu/SET_QINIU_INFO", tokenData);
};
const logout = () => {
    ElNotification({
        title: "提示信息",
        message: "登录信息已过期，请重新登录...！",
        type: "error",
        duration: 1000,
        onClose: () => {
            store.commit("user/LOGOUT");
            // store.commit("wsData/CLEAR_SYS_CFG_INFO", {});
            // store.commit("wsData/SET_CHATING_USERID", 0);
            router.push("/login");
        },
    });
}

//用户信息变更
const handelUserInfoChange = (message) => {
    let userInfo = message.data;
    store.commit("user/UPDATE_USER", userInfo);
};

//接收账号信息变更
const receiveAccountList = async (accountListResp) => {
    if (accountListResp && accountListResp.code === 1) {
        // store.commit('wsData/UPDATE_ACCOUNT_LIST', accountListResp)
        store.commit('chat_store/setUserAccounts', accountListResp)
    }
}

const receiveAiStatusResult = (resp) => {
    console.log('----------------')
    console.log(resp)
}

//好友信息变更
function receiveAccountFriendList(accountFriendListResp) {
    if (accountFriendListResp && accountFriendListResp.code === 1) {
        // store.commit("wsData/SET_ACCOUNT_FRIEND_LIST", accountFriendListResp);
        store.commit('chat_store/setUserAccountFriends', accountFriendListResp)

        // 如果是，子命令：3_1   好友聊天人工接管标志变更,此处处理是为了兼容刷新页面后第一次
        // let sub_t = accountFriendListResp.sub_t
        // if (sub_t === '3_1') {
        //     let updateFriends = accountFriendListResp?.data?.friendlist
        //     if (updateFriends?.length > 0) {
        //         let updateFriend = updateFriends[0]
        //         if (updateFriend.cid === selectedAccountFriendId.value) {
        //             //更新好友的AI接管标识
        //             store.commit("wsData/SET_CHATING_FRIEND_AIFLAG", updateFriend.is_artificial);
        //         }
        //     }
        // }
    }
}
//账号好友数量变更
const receiveAccountFriendsCountChange = async (resData) => {
    if (resData && resData.code === 1) {
        // store.commit('wsData/UPDATE_ACCOUNT_LIST', accountListResp)
        store.commit('chat_store/setAccountFriendCount', resData.data)
    }
}
//加载账号更多好友
const handleLoreMoreFriend = (data) => {
    data.limit = SELECT_FRIEND_LIMIT
    getAccountFriendList(data)
}

// -------------- 生命周期 --------------
onMounted(async () => {
    //监听系统配置初始化
    bus.on(`${wsCmd.cmd_99_resp}`, handleSystemCfgInit); // listen
    //监听七牛云信息
    bus.on(`${wsCmd.cmd_98_resp}`, handleQiniuTokenResp); // listen
    //监听退出
    bus.on(eventbusCms.logout, logout); // listen
    //用户信息变更
    bus.on(`${wsCmd.cmd_101_resp}`, handelUserInfoChange);
    //接收平台账号信息
    bus.on(`${wsCmd.cmd_2_resp}`, receiveAccountList); // listen
    //AI状态变更
    bus.on(`${wsCmd.cmd_21_resp}`, receiveAiStatusResult);
    //账号好友信息变更
    bus.on(`${wsCmd.cmd_3_resp}`, receiveAccountFriendList); // listen
    //账号好友数量变更
    bus.on(`${wsCmd.cmd_29_resp}`, receiveAccountFriendsCountChange); // listen
    //加载更多好友
    bus.on(eventbusCms.loadMoreAccountFriend, handleLoreMoreFriend); // listen
})

onUnmounted(() => {

    //监听事件start----------
    bus.off(`${wsCmd.cmd_99_resp}`); // listen
    bus.off(`${wsCmd.cmd_98_resp}`); // listen
    bus.off(eventbusCms.logout); // listen
    bus.off(`${wsCmd.cmd_101_resp}`);
    //监听事件end----------
});

</script>