import indexedDb from '@/indexeddb/index'
import chatDbkey from './chat_db_keys'


/**
 * 获取当前对话用户ID
 * @returns 
 */
export function getChattingUserId() {
  let key = chatDbkey.getChatingUserIdKey()
  let userId = localStorage.getItem(key)
  return userId
}

/**
 * 设置当前对话用户ID
 * @param {*} user_id 
 */
export function setChattingUserId(user_id) {
  let key = chatDbkey.getChatingUserIdKey()
  localStorage.setItem(key, user_id)
}

/**
 * 获取当前用户的会话平台
 * @returns 
 */
export function getUserChatPlat() {
  let userId = getChattingUserId()
  if (!userId) {
    return undefined
  }
  let key = chatDbkey.getUserChatPlatKey(userId)

  let chatPlat = localStorage.getItem(key)
  return chatPlat
}

/**
 * 设置当前用户的会话平台
 * @returns 
 */
export function setUserChatPlat(plattype) {
  let userId = getChattingUserId()
  if (!userId) {
    return
  }
  let key = chatDbkey.getUserChatPlatKey(userId)
  localStorage.setItem(key, plattype)
}


/**
 * 设置系统配置信息
 * @param {} data 
 * @param {*} chatingUserid 
 * @returns 
 */
export function setSyCfgInfo(data) {
  if (!data) {
    data = {}
  }
  let userId = getChattingUserId()


  let key = chatDbkey.getUserConfigKey(userId)
  let key2 = chatDbkey.getUserConfigInitKey(userId)
  localStorage.setItem(key2, true)
  return indexedDb.setItem(key, data)
}

/**
 * 获取系统配置
 * @param {} chatingUserid 
 * @returns 
 */
export function getSyCfgInfo() {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserConfigKey(userId)

  return new Promise((resolve) => {
    indexedDb.getItem(key).then(sysCfgJson => {
      if (!sysCfgJson) {
        return resolve({})
      }
      return resolve(sysCfgJson)
    })

  })
}


export function getSyCfgInfoInited() {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserConfigInitKey(userId)
  let inited = localStorage.getItem(key) || false
  return inited
}

/**
 * 获取用户账号列表
 * @param {} plattypeId 
 */
export function getUserAccounts(plattypeId) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountsKey(userId, plattypeId)
  return new Promise((resolve) => {
    indexedDb.getItem(key).then(userAccounts => {
      let data = userAccounts?.data
      if (!data) {
        return resolve([])
      }
      return resolve(data)
    })
  })
}
/**
 * 设置指定平台用户账号列表
 * @param {} plattypeId 
 */
export function setUserAccounts(plattypeId, accounts) {
  let userId = getChattingUserId()

  let key = chatDbkey.getUserAccountsKey(userId, plattypeId)
  let data = { data: accounts || [] }
  indexedDb.setItem(key, data)
}
/**
 * 获取当前中的用户账号
 * @param {} plattypeId 
 */
export function getUserAccountSelect(plattypeId) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountSelectKey(userId, plattypeId)
  return new Promise((resolve) => {
    indexedDb.getItem(key).then(selectAccount => {
      if (!selectAccount) {
        return resolve({})
      }
      return resolve(selectAccount)
    })
  })
}
/**
 * 设置平台选中的账号信息
 * @param {} plattypeId 
 */
export function setUserAccountSelect(plattypeId, accountInfo) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountSelectKey(userId, plattypeId)
  indexedDb.setItem(key, accountInfo)
}
/**
 * 获取当前中的用户账号好友列表
 * @param {} accountId 
 */
export function getUserAccountFriends(accountId) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountFriendsKey(userId, accountId)
  return new Promise((resolve) => {
    indexedDb.getItem(key).then(accountFriends => {
      let data = accountFriends?.data
      if (!data) {
        return resolve([])
      }
      return resolve(data)
    })
  })
}


/**
 * 设置账号好友列表
 * @param {} accountId 
 */
export function setUserAccountFriends(accountId, accountFriends) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountFriendsKey(userId, accountId)
  let data = {data:accountFriends || []}
  indexedDb.setItem(key, data)
}


/**
 * 获取当账号选中好友
 * @param {} accountId 
 */
export function getUserAccountSelectFriend(accountId) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountSelectFriendKey(userId, accountId)
  return new Promise((resolve) => {
    indexedDb.getItem(key).then(accountFriend => {
      if (!accountFriend) {
        return resolve({})
      }
      return resolve(accountFriend)
    })
  })
}

/**
 * 设置当账号选中好友
 * @param {} accountId 
 */
export function setUserAccountSelectFriend(accountId, selectFriend) {
  let userId = getChattingUserId()
  let key = chatDbkey.getUserAccountSelectFriendKey(userId, accountId)
  indexedDb.setItem(key, selectFriend)
}



export default {
  getChattingUserId,
  setChattingUserId,
  getSyCfgInfo,
  setSyCfgInfo,
  getSyCfgInfoInited,
  getUserAccounts,
  setUserAccounts,
  getUserAccountSelect,
  setUserAccountSelect,
  getUserAccountFriends,
  setUserAccountFriends,
  getUserChatPlat,
  setUserChatPlat,
  getUserAccountSelectFriend,
  setUserAccountSelectFriend,
}