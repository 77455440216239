import { mergeList, deleteAll,replaceAll } from '@/utils/chat_data_array_utils'


/**
 * 
 * @param {*} type  动作类型 1全部，2新增，3更新，4删除，默认1
 * @param {*} oldAccountList 旧账号数据
 * @param {*} receiveAccountList 接收到的账号数据  
 */
export function processAccountList(type, oldAccountList, receiveAccountList) {
    if (type == 1) {
        return replaceAll(oldAccountList, receiveAccountList)
    } else if (type == 2) {
        return mergeList(oldAccountList, receiveAccountList)
    } else if (type == 3) {
        return mergeList(oldAccountList, receiveAccountList, 'id', 'id', (oldData, newData) => {
            //如果新数据没有包含端口信息那么就替换端口信息
            if (newData && !newData?.port_id) {
                newData.port_id = oldData?.port_id
                newData.end_flg = oldData?.end_flg
                newData.expire_time = oldData?.expire_time
                newData.expire_day = oldData?.expire_day
            }
            return newData;
        })
    } else if (type == 4) {
        return deleteAll(oldAccountList, receiveAccountList)
    }
    return []
}

/**
 * 
 * @param {*} type  动作类型 1全部，2新增，3更新，4删除，默认1
 * @param {*} oldAccountList 旧账号好友数据
 * @param {*} receiveAccountList 接收到的账号好友数据  
 */
export function processAccountFriendList(type, oldAccountList, receiveAccountList) {
    if (type == 1) {
        return replaceAll(oldAccountList, receiveAccountList)
    } else if (type == 2) {
        return mergeList(oldAccountList, receiveAccountList,'cid', 'cid')
    } else if (type == 3) {
        return mergeList(oldAccountList, receiveAccountList, 'cid', 'cid', (oldData, newData) => {
            //如果新数据没有包含端口信息那么就替换端口信息
            if (newData && !newData?.port_id) {
                newData.port_id = oldData?.port_id
                newData.end_flg = oldData?.end_flg
                newData.expire_time = oldData?.expire_time
                newData.expire_day = oldData?.expire_day
            }
            return newData;
        })
    } else if (type == 4) {
        return deleteAll(oldAccountList, receiveAccountList,'cid', 'cid')
    }
    return []
}