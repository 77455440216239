<!-- //中间栏模块 -->
<template>
  <div class="sidebar_right" v-if="selectedAccountFriendId" ref="sidebarRightRef">

    <div class="sidebar_right_container">
      <div class="ntMain__head" style="align-items: center;display: flex;justify-content: center;">
        <div class="flexbox" >
          <h2
            class="hdtit flex1"
            style="font-size: 14px; color: #282828; text-align: center;"
          >
            {{ activeComponent.title }}
          </h2>
        </div>
      </div>

      <div class="sidebar_right_main">
        <!-- <div class="l">
          <div
            class="l_item"
            @click="handleItemClick(1)"
            :class="{ l_item_active: active_item == 1 }"
          >
            <el-tooltip
              content="好友信息"
              effect="customized"
              placement="right"
            >
              <div class="l_item_tip">
                <i class="iconfont icon-person"></i>
              </div>
            </el-tooltip>
          </div>

          <div
            class="l_item"
            @click="handleItemClick(2)"
            :class="{ l_item_active: active_item == 2 }"
          >
            <el-tooltip
              content="聊天记录"
              effect="customized"
              placement="right"
            >
              <div class="l_item_tip">
                <i class="iconfont icon-rizhi"></i>
              </div>
            </el-tooltip>
          </div>

          <div
            class="l_item"
            @click="handleItemClick(3)"
            :class="{ l_item_active: active_item == 3 }"
          >
            <el-tooltip
              content="好友信息"
              effect="customized"
              placement="right"
            >
              <div class="l_item_tip">
                <i class="iconfont icon-custom"></i>
              </div>
            </el-tooltip>
          </div>
        </div> -->
        <div class="r" style="box-sizing: border-box">
          <component :is="activeComponent.component_item" />
          <!-- <router-view name="sidebarRight"></router-view> -->
        </div>
      </div>
    </div>
    <!-- <div class="resizeBar_p" @click.once="initResizeBarEvent">
      <div class="resizeBar" id="resizeBar" ref="resizeBarRef" >⋮</div>
    </div> -->

  </div>
</template>
  <script>
// 引入动态组件
import chatingCustomInfo from "@/views/sidebarRight/chatingCustomInfo";
import customChatlogHistory from "@/views/sidebarRight/customChatlogHistory";

import { toRefs, onMounted, reactive, computed, watch,ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
export default {
  components: {
    chatingCustomInfo: chatingCustomInfo,
    customChatlogHistory: customChatlogHistory,
  },
  setup() {
    let store = useStore();
    // const router = useRouter();
    console.log('------------------',store.getters['sidebarRight/getActiveComponent'])
    console.log(store.state.sidebarRight)

    const data = reactive({
      // activeComponent: store.getters['sidebarRight/getActiveComponent'],
      active_item: 1,
      title: "好友信息",
      rightBarDomEventInited:false,

    });


    // 激活显示的右侧组件
    const activeComponent = computed(()=>{
       return store.state.sidebarRight.activeComponent
    })

    const sidebarRightRef = ref(null)
    const resizeBarRef = ref(null)

    //当前选中账号
    const selectedAccountId = computed(() => {
      return store.state.chat_store.user_account_select?.id
    });
    //当前选中好友
    const selectedAccountFriendId = computed(() => {
      return store.state.chat_store.user_account_select_friend?.cid
    });

    function handleItemClick(item) {
      data.active_item = item;
      if (item == 1) {
        data.activeComponent = "chatingCustomInfo";
        data.title = "好友信息";
        //router.push({ name: "sbr_chatCustomInfo", query: { id: item.cid } });
      } else if (item == 2) {
        data.title = "好友历史记录";
        data.activeComponent = "customChatlogHistory";
        //router.push({ name: "sbr_chatlogHistory", query: { id: item.cid } });
      }
    }

    //当前聊天好友
    const chatingFriendInfo = computed(() => {
      return store.state.chat_store.user_account_select_friend
    });

    const initRightBarWidth = ()=>{
      if(store.state.user.rightBarWidth > 0 && sidebarRightRef.value){
        sidebarRightRef.value.style = `width: ${store.state.user.rightBarWidth}px`;
      }
    }

    watch(
      () => chatingFriendInfo,
      () => {
        store.dispatch('sidebarRight/resetActiveComponent')
      },
      { deep: true }
    );

    onMounted(() => {

      handleItemClick(data.active_item);
  
      initRightBarWidth()
       
   
    });

    // onUpdated(()=>{
    //   nextTick(() => {
    //      initRightBarWidth()
    //   });
    // })
    //拖拽
    const initResizeBarEvent = ()=>{
      dragControllerDiv();
    }
    async function dragControllerDiv() {
      //await nextTick()
      let resizeBar = document.getElementById("resizeBar");
      //let resizeBar = resizeBarRef.value
      if (!resizeBar ) {
        return;
      }

      var resizeElementChild = resizeBar.offsetParent;
      if (!resizeElementChild) {
        return;
      }
      //再向上找一级找到sidebar
      var resizeElement = resizeElementChild.offsetParent;
      if (!resizeElement) {
        return;
      }
  

      /*鼠标 按下拖拽区 */
      resizeBar.onmousedown = function (e) {
        data.rightBarDomEventInited = true
        // 拖拽区 开始的距离  403
        var startX = e.clientX;
        let startParentWidth = resizeElement.clientWidth

        // 左边大小 放入 resize
        //resizeBar.left = resizeBar.offsetLeft;
        /* 鼠标拖拽 */
        document.onmousemove = function (ee) {
          // 拖拽区 结束的距离
          var endX = ee.clientX;
          // 移动的距离 （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
          let leftWidth = (endX - startX);
 
          // 设置 左边宽度
          let newWidth = (startParentWidth - leftWidth)
          resizeBar.leftWidth = newWidth
          resizeElement.style.width = newWidth + "px";
         
        };
        /* 鼠标松开 */
        document.onmouseup = function () {
          let newWidth = resizeBar.leftWidth
        
          if(newWidth && newWidth > 0){
            store.commit("user/SET_RIGHT_BAR_WIDTH", newWidth);
          }
          // 取消事件
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    }
    return {
      ...toRefs(data),
      handleItemClick,
      initRightBarWidth,
      initResizeBarEvent,
      activeComponent,
      sidebarRightRef,
      resizeBarRef,
      chatingFriendInfo,
      selectedAccountFriendId,
    };
  },
};
</script>

<style>
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
</style>

<style lang="scss" scoped>
.sidebar_right {
  box-sizing: border-box;
  border:2px solid rgba(223, 229, 244, 0.8);
  border-radius: 10px;
  overflow: hidden;
  // width: 400px;
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .resizeBar_p {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    box-sizing: border-box;
    width: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: red;
  
  }
  .resizeBar{
    height: 50px;
    cursor: col-resize;
  }

  .sidebar_right_container {
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    .sidebar_right_main {
      flex-grow: 1;
      display: flex;
      position: relative;
      .l {
        flex-shrink: 0;
        width: 25px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        // box-shadow: -17px 0px 50px 35px rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(
          180deg,
          rgba(235, 233, 233, 0.4) 50%,
          rgba(235, 242, 243, 0.4)
        );
        .l_item {
          width: 100%;
          height: 30px;
          box-sizing: border-box;
          flex-direction: column;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          .l_item_tip {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .l_item:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
        .l_item_active {
          background-color: rgba(0, 0, 0, 0.1);
          color: black;
        }
      }

      .r {
        overflow: hidden;
        flex-grow: 1;
        height: 100%;
        background-image: linear-gradient(
          180deg,
          rgba(235, 233, 233, 0.1) 50%,
          rgba(235, 242, 243, 0.1)
        );
      }
    }
  }
}
</style>
     
     