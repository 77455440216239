const selectFriendChange = 'selectFriendChange'
const logout = 'logout'
const chatLogListChange = 'chatLogListChange'
const chatLogListNomore = 'chatLogListNomore'
const sysCfgInitSuccess = 'sysCfgInitSuccess'
//发送好友消息
const sendFriendMsg = 'sendFriendMsg'
//加载账号更多好友
const loadMoreAccountFriend = "load_more_account_friend"
//消息命令
export default {
    selectFriendChange,
    logout,
    sysCfgInitSuccess,
    chatLogListChange,
    chatLogListNomore,
    sendFriendMsg,
    loadMoreAccountFriend,
  
}