/**
 * Vue3.0路由管理
 */
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { ElNotification } from 'element-plus'
import ChatLayout from '@/layouts/chat-layout.vue'
// import V3Layer from '@components/v3layer'
const routesLS = [
  {
    name: 'login', path: '/login',
    component: () => import('../views/auth/login.vue'), meta: { hideWinBar: true, hideSideBar: true, hideMiddle: true,hideSideBarLeft:true }
  },
  {
    name: 'register', path: '/register', component: () => import('../views/auth/register.vue'),
    meta: { hideWinBar: true, hideSideBar: true, hideMiddle: true }
  }, {
    name: 'index', path: '/', redirect: { path: '/chat/group-chat' }, component: () => import('../views/index'),
    meta: { requireAuth: true, showLeft: true }
  },
  {
    name: 'contact', path: '/contact', component: () => import('../views/contact'), meta: { requireAuth: true,showLeft:true }
  }, {
    name: 'uinfo', path: '/contact/uinfo', component: () => import('../views/contact/uinfo.vue'),meta:{showLeft:true}
  },
  {
    name: 'newFriend', path: '/contact/new-friends', component: () => import('../views/contact/new-friends.vue'),meta:{showLeft:true}
  }, {
    name: 'favorite', path: '/my/favorite', component: () => import('../views/my/favorite.vue'), meta: { requireAuth: true }
  },
  {
    name: 'setting', path: '/my/setting', component: () => import('../views/my/setting.vue'), meta: { requireAuth: true }
  }, {
    name: 'fzone', path: '/fzone', component: () => import('../views/fzone'), meta: { requireAuth: true, hideMiddle: true }
  },
  //聊天主界面
  {
    path: '/',
    name: '_home',
    redirect: { name: 'chatpage' },
    component: ChatLayout,
    children: [
      {
        name: 'groupChat', path: '/chat/group-chat',
        components: { default: () => import('../views/chat/group-chat'), 
        // sidebarRight:() => import('../views/sidebarRight/chatingCustomInfo.vue')
      },
        meta: { requireAuth: true,showLeft:true,showRight:true,sidebarRight:true }
      },
    ]
  },
  //统计信息
  {
    name: 'statistics', path: '/statistics/index', component: () => import('../views/statistics/index.vue'),
    meta: { requireAuth: true }
  },
  {
    name: 'groupInfo', path: '/chat/group-chat/groupinfo', component: () => import('../views/chat/group-chat/groupInfo.vue'),
    meta: { requireAuth: true }
  }, {
    name: 'redPacket', path: '/chat/group-chat/redpacket', component: () => import('../views/chat/group-chat/redPacket.vue'),
    meta: { requireAuth: true }
  },

    {
      name:'sbr',
      path: '/sbr',
      children: [
        {
          // 当 /user/:id/profile 匹配成功，
          // UserProfile 会被渲染在 User 的 <router-view> 中
          name:'sbr_chatlogHistory',
          path: 'customChalogHistory',
          components: { 
            default:  () => import('../views/chat/group-chat'),
            // sidebarRight: () => import('../views/sidebarRight/customChatlogHistory.vue')
          },
          meta: { requireAuth: true,showLeft:true,showRight:true,sidebarRight:true }
        },
        {
          // 当 /user/:id/profile 匹配成功，
          // UserProfile 会被渲染在 User 的 <router-view> 中
          name:'sbr_chatCustomInfo',
          path: 'chatingCustomInfo',
          components: { 
            default:  () => import('../views/chat/group-chat'),
            // sidebarRight: () => import('../views/sidebarRight/chatingCustomInfo.vue')
          },
          meta: { requireAuth: true,showLeft:true,showRight:true,sidebarRight:true }
        },
      ]
    }
    
]
const router = createRouter({
  history: createWebHistory(), routes: routesLS,
})
// 全局钩子拦截登录状态
router.beforeEach((to, from, next) => {
  const token = store.state.user.token
  const sysCfgInfoInited = store.state.chat_store.sysCfgInfoInited

  // const userInfo = store.state.user.user
 // && (chatingUserid != 0) && (userInfo?.uid == chatingUserid)
  if (to.meta.requireAuth) {
    //并且系统配置信息加载完成
    if (token && sysCfgInfoInited) {
      next()
    } else {
      
      ElNotification({
        title: '提示信息',
        message: '还未登录授权,请重新登录!',
        type: 'error',
        duration:1000,
        onClose:()=>{
          next({ path: '/login' })
        }
      })
      // V3Layer({
      //   content: '还未登录授权！', position: 'top', layerStyle: 'background:#fa5151;color:#fff;', time: 2,
      //   onEnd: () => {
      //     next({ path: '/login' })
      //   }
      // })
    }
  } else {
    next()
  }
})
export default router