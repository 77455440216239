import { parseKey } from '@/utils/keyUtils'
// key base name
const USER_ID = 'user_id'

const PLAT_TYPE_ID = 'plattype_id'

const ACCOUNT_ID = 'account_id'

// ---------存储的key---------------


//会话用户ID
const CHATING_USER_ID = 'chat_user_id';

const CHATING_PLAT_ID = `user:{${USER_ID}}:chat_plat`

//用户配置信息:{}
const USER_CONFIG = `user:{${USER_ID}}:system_config_info`
const USER_CONFIG_INITED = `user:{${USER_ID}}:system_config_info:inited`

//用户账号列表:[{}]
const USER_ACCOUNTS = `user:{${USER_ID}}:plattype:{${PLAT_TYPE_ID}}:accounts`

//用户选中账号:{}
const USER_ACCOUNT_SELECT = `user:{${USER_ID}}:plattype:{${PLAT_TYPE_ID}}:select_account`

//用户下的某账号的好友信息:[{}]
const USER_ACCOUNT_FRIENDS = `user:{${USER_ID}}:account:{${ACCOUNT_ID}}:friends`

//用户账号选中的好友 = {}
const USER_ACCOUNT_SELECT_FRIEND = `user:{${USER_ID}}:account:{${ACCOUNT_ID}}:select_friend`

//用户账号的好友数量:100
const USER_ACCOUNT_FRIEND_COUNT = `user:{${USER_ID}}:account:{${ACCOUNT_ID}}:friend_count`


/**
 * 获取当前登录用户ID
 * @returns
 */
function getChatingUserIdKey() {
    return CHATING_USER_ID;
}

/**
 * 获取用户对话平台ID
 * @returns
 */
function getUserChatPlatKey(userId) {
    let keyData = {}
    keyData[USER_ID] = userId
    return parseKey(CHATING_PLAT_ID, keyData)
}
/**
 * 获取用户配置信息KEY
 * @param {*} userId 
 * @returns 
 */
function getUserConfigKey(userId) {
    let keyData = {}
    keyData[USER_ID] = userId
    return parseKey(USER_CONFIG, keyData)
}
/**
 * 获取用户配置信息是否已初始化key
 * @param {*} userId 
 * @returns 
 */
function getUserConfigInitKey(userId) {
    let keyData = {}
    keyData[USER_ID] = userId
    return parseKey(USER_CONFIG_INITED, keyData)
}

/**
 * 获取用户账号列表Key
 * @param {} userId 
 * @param {*} plattype_id 
 */
function getUserAccountsKey(userId, plattype_id) {
    let keyData = {}
    keyData[USER_ID] = userId
    keyData[PLAT_TYPE_ID] = plattype_id
    return parseKey(USER_ACCOUNTS, keyData)
}
/**
 * 获取用户选择账号Key
 * @param {} userId 
 * @param {*} plattype_id 
 */
function getUserAccountSelectKey(userId,plattype_id) {
    let keyData = {}
    keyData[USER_ID] = userId
    keyData[PLAT_TYPE_ID] = plattype_id
    return parseKey(USER_ACCOUNT_SELECT, keyData)
}
/**
 * 获取用户指定账号好友列表
 * @param {} userId 
 * @param {*} plattype_id 
 */
function getUserAccountFriendsKey(userId, account_id) {
    let keyData = {}
    keyData[USER_ID] = userId
    keyData[ACCOUNT_ID] = account_id
    return parseKey(USER_ACCOUNT_FRIENDS, keyData)
}

/**
 * 获取用户指定账号当前选中好友
 * @param {} userId 
 * @param {*} plattype_id 
 */
function getUserAccountSelectFriendKey(userId, account_id) {
    let keyData = {}
    keyData[USER_ID] = userId
    keyData[ACCOUNT_ID] = account_id
    return parseKey(USER_ACCOUNT_SELECT_FRIEND, keyData)
}

/**
 * 获取用户指定账号选中好友的数量
 * @param {} userId 
 * @param {*} plattype_id 
 */
function getUserAccountFriendCountKey(userId, account_id) {
    let keyData = {}
    keyData[USER_ID] = userId
    keyData[ACCOUNT_ID] = account_id
    return parseKey(USER_ACCOUNT_FRIEND_COUNT, keyData)
}


export default {
    getUserChatPlatKey,
    getChatingUserIdKey,
    getUserConfigKey,
    getUserConfigInitKey,
    getUserAccountsKey,
    getUserAccountSelectKey,
    getUserAccountFriendsKey,
    getUserAccountSelectFriendKey,
    getUserAccountFriendCountKey,
}