//存储变量匹配
const regex = /\{([^}]+)\}/g;

/**
 * 变量key替换
 * @param {} key  user:{user_id}:plattype:{plattype_id}:accounts
 * @param {*} keyData   { user_id: 123, plattype_id: 456 }
 * @returns user:123:plattype:456:accounts
 */
export function parseKey(key, keyData) {
  return key.replace(regex, (match, key) => {
    return keyData[key] || match
  });
}
