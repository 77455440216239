import { createStore,createLogger } from 'vuex'
import user from './modules/user'
import websocket from './modules/websocket'
import wsData from './modules/wsData'
import chat from './modules/chat'
import qiniu from './modules/qiniu'
import sidebarRight from './modules/sidebarRight'
import profile from './modules/profile'
import chat_store from './modules/chat_store'

//const debug = process.env.NODE_ENV !== 'production2'
const debug = false//process.env.NODE_ENV !== 'production'


export default createStore({
  modules: {
    user,
    websocket,
    wsData,
    chat,
    qiniu,
    sidebarRight,
    profile,
    chat_store
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
