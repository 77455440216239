import _ from 'lodash';
//合并消息列表并排序,默认字段唯一标识是id
/**
 * 
 * @param {旧数据列表} oldList 
 * @param {新数据列表} newList 
 * @param {id标识} idx_flag1 
 * @param {id标识} idx_flag2 
 * @param {新旧数据替换处理方法} replaceProcessFn 
 * @returns 
 */
export function mergeList(oldList, newList, idx_flag1, idx_flag2, replaceProcessFn) {
    return new Promise((resolve) => {
        let flag = 'id'
        let _idx_flag1 = idx_flag1 || flag
        let _idx_flag2 = idx_flag2 || flag
        if (!oldList && !newList) {
            return resolve([])
        }


        if (!oldList || oldList.length == 0) {
            oldList.push(...newList)
            return resolve([])
        }

        if (!newList || newList.length == 0) {
            return resolve([])
        }

        for (let i = 0; i < newList.length; i++) {
            let id = newList[i][_idx_flag2]
            let findIdx = oldList.findIndex(item => item[_idx_flag1] == id)
            if (findIdx !== -1) {
                let newData = newList[i]
                //自定义替换方法
                if (replaceProcessFn && _.isFunction(replaceProcessFn)) {
                    let oldData = oldList[findIdx]
                    let _newData = replaceProcessFn(oldData, newData)
                    if (_newData && _newData[_idx_flag2]) {
                        newData = _newData
                    }
                }
                //替换这个元素
                oldList.splice(findIdx, 1, newData)
            } else {
                oldList.push(newList[i])
            }
        }
        return resolve(oldList)
    })

}

//合并并排序 默认字段唯一标识是id
export function mergeListAndSort(oldList, newList) {
    let res = mergeList(oldList, newList)
    res.sort((item1, item2) => item1.id - item2.id)
    return res
}

//合并并排序 默认字段唯一标识是id
export function mergeListAndSortDesc(oldList, newList) {
    let res = mergeList(oldList, newList)
    res.sort((item1, item2) => item2.id - item1.id)
    return res
}

//替换所有
export function replaceAll(oldList, newList) {
    return new Promise((resolve) => {
        if (!oldList && !newList) {
            return resolve([])
        }
        oldList.splice(0, oldList.length);
        oldList.push(...newList)
        resolve(oldList)
    })

}



//删除所有
export function deleteAll(oldList, newList, idx_flag1, idx_flag2) {
    return new Promise((resolve) => {
        let flag = 'id'
        let _idx_flag1 = idx_flag1 || flag
        let _idx_flag2 = idx_flag2 || flag
        if (!oldList && !newList) {
            return resolve([])
        }
        if (!newList) {
            return resolve(oldList)
        }

        for (let i = 0; i < newList.length; i++) {
            let item = newList[i]
            let id = item[_idx_flag2]
            let findIdx = oldList.findIndex(item => item[_idx_flag1] == id)
            if (findIdx !== -1) {
                oldList.splice(findIdx, 1)
            }
        }
        return resolve(oldList)
    })


}